import React, { useEffect, useState, useMemo } from 'react'
import { LandingPageHero } from 'src/components/templateComponents';
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import findSiteType from "src/components/helpers/findSiteType";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getSchemaMarkup from 'src/components/helpers/getSchemaMarkup';
import loadable from '@loadable/component'
// import "src/scss/landingpages/_landingPage.scss";
import { SEO } from 'src/components/commonComponents';
import { getPageDataJsonPath } from "src/utils";
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 const RightAlignContent = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.RightAlignContent
});
const LeftAlignContent = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.LeftAlignContent
});
const ScrollLinks = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.SecondaryNavbar});
const HeroBg = "/newhomepage/Hero-background.svg";

const RightAlignForm = ({ formProps, fieldAddComponentWithMarketo, locale }) => {
  return (
    <div className="section-container">
      <div className='section'>
        <RightAlignContent contents={fieldAddComponentWithMarketo} formProps={formProps} locale={locale} />
      </div>
    </div>
  )
}
const LeftAlignForm = ({ formProps, fieldAddComponentWithMarketo, locale }) => {
  return (
    <div className="section-container">
      <div className='section'>
        <LeftAlignContent contents={fieldAddComponentWithMarketo} formProps={formProps} locale={locale} />
      </div>
    </div>
  )
}

const LandingPageTemplate = ({ pageContext, path }) => {
  const [isFormLoaded, setFormLoaded] = useState(false)
  const { fieldHeroDescription, fieldHeroTitle, fieldHeroCtaUrl } = pageContext;
  const version = pageContext?.fieldLandingPageTemplate;
  const locale = pageContext.locale || "en";
  const marketoFormEntity = pageContext.relationships?.fieldMarketoFormNode;
  const { id, serial, gaLabel } = extractMarketoValues(
    marketoFormEntity?.fieldEmbedCode
  );
  const formCallback = () => {
    setFormLoaded(true)
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  const fieldFormHeader = marketoFormEntity?.fieldFormHeader
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      pageContext.relationships?.fieldContentSections,
      "landingPage",
      locale
    );
  }
  const anchors = getContentConnectorAnchors(contentConnectors);

  let hreflangAlternatives = []
  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if(hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  const heroImage = pageContext?.commonHeroProps?.heroImage
  const heroProps = {
    fieldHeroDescription: fieldHeroDescription,
    fieldHeroMedia: heroImage,
    title: fieldHeroTitle,
    fieldHeroCtaUrl: fieldHeroCtaUrl,
    formCta: pageContext?.commonHeroProps?.formCta
  }
  const formProps = {
    form: formTag,
    fieldFormHeader: fieldFormHeader,
    isFormLoaded: isFormLoaded,
    title: marketoFormEntity?.title
  }
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');
  const fieldUseMarketoFormOnHeroS = pageContext?.fieldUseMarketoFormOnHeroS;

  const OGMarkup = getOGMarkup(pageContext?.fieldHeroTitle,'landing page', { gatsbyImage: heroImage?.shareImage });
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  return (
    <div className='landing-page'>
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        title={pageContext?.title}
        meta={pageContext?.entityMetatags}
        alternatives={hreflangAlternatives}
        schemaMarkup={schemaMarkup}
        OGMarkup={OGMarkup}
        heroImage={heroImage}
        preloads= {[pageDataPath]}
        preloadedImages={[{src: HeroBg}]}
      />
      <LandingPageHero
        heroProps={heroProps}
      />
      <div className='container content-visibility-auto-mobile'>
        {!!anchors.length &&
          <ScrollLinks
            title={pageContext.fieldTermJumpLinksHeader}
            anchors={anchors}
            offset={-185}
          />
        }
        {fieldUseMarketoFormOnHeroS === "market_form_right_align" ?
          <RightAlignForm formProps={formProps} fieldAddComponentWithMarketo={pageContext?.relationships?.fieldAddComponentWithMarketo}
            locale={locale}
          /> : fieldUseMarketoFormOnHeroS === "market_form_left_align" &&
          <LeftAlignForm formProps={formProps} fieldAddComponentWithMarketo={pageContext?.relationships?.fieldAddComponentWithMarketo}
            locale={locale} />
        }
        {contentConnectors.map((data, index) => (
          <div key={index} className={`section-container ${version} ${isMc ? "mc" : isEms ? "ems" : "accruent"}`}>
            <ContentConnector
              key={index}
              data={data}
              pageType={pageContext.type?.id}
              locale={locale}
            />
          </div>
        ))}
      </div>

    </div>

  )
}
export default LandingPageTemplate;
